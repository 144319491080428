import React from 'react';
import { Form } from 'react-bootstrap';
const MaterialComponent = () => {
    return (
      <Form.Group controlId="formMaterial" style={{marginBottom: "20px"}}>
        <Form.Control type="text" placeholder="Enter material" required />
      </Form.Group>
    );
  };

export default MaterialComponent;
