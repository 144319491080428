import React from 'react';
import { Form } from 'react-bootstrap';

const NameComponent = () => {
  return (
    <Form.Group controlId="formName" style={{marginBottom: "20px"}}>
      <Form.Control type="text" placeholder="Enter name" required/>
    </Form.Group>
  );
};

export default NameComponent;
