import React, { useEffect } from 'react';
import favicon from './favicon.ico';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import AuthProvider from '../contexts/AuthContext';
import Landingpage from './Landingpage.js';
import Contact from './Contact.js';
import Policies from './Policies.js';
import PrivateRoute from './PrivateRoute'
import AdminLogin from './AdminLogin';
import AdminDashboard from './AdminDashboard';
import ItemPage from './ItemPage';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom';
function App() {
  const { affiliateID } = useParams();
  useEffect(() => {
    // Update favicon
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.head.appendChild(link);
    }
    link.href = favicon;

    // Update title
    document.title = 'Checkerz';

    // Update description
    let meta = document.querySelector("meta[name='description']");
    if (!meta) {
        meta = document.createElement('meta');
        meta.name = 'description';
        document.head.appendChild(meta);
    }
    meta.content = 'A Great Service to Generate Receipts';
  }, []);
  return (
    <div style={{backgroundColor: "#e1e1e1"}}>
      <Router>
        <AuthProvider>
          <Routes>
            <Route path='/:affiliateID?' element={<Landingpage/>}/>
            <Route path='/contact/:affiliateID?' element={<Contact/>}/>
            <Route path='/policies/:affiliateID?' element={<Policies/>}/>
            <Route exact path='/admin' element={<AdminLogin/>}/>
            <Route path="/item/:id/:affiliateID?" element={<ItemPage/>} />
            <Route exact path='/admin/dashboard' element={<PrivateRoute><AdminDashboard/></PrivateRoute>}/>          </Routes>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
