import React from 'react';
import { Form } from 'react-bootstrap';

const TotalComponent = () => {
    return (
      <Form.Group controlId="formTotal" style={{marginBottom: "20px"}}>
        <Form.Control type="text" placeholder="Enter total" required/>
      </Form.Group>
    );
  };
export default TotalComponent;
