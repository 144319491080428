import React from 'react';
import { Form } from 'react-bootstrap';
const SizeForMenComponent = () => {
    return (
      <Form.Group controlId="formSizeForMen" style={{marginBottom: "20px"}}>
        <Form.Control type="text" placeholder="Enter size for men" required/>
      </Form.Group>
    );
  };
export default SizeForMenComponent;
