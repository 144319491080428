import React from 'react';
import { Form } from 'react-bootstrap';

const EUSizeComponent = () => {
    return (
      <Form.Group controlId="formEUSize" style={{marginBottom: "20px"}}>
        <Form.Control type="text" placeholder="Enter EU size" required/>
      </Form.Group>
    );
  };

  export default EUSizeComponent;
