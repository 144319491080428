import React from 'react';
import { Form } from 'react-bootstrap';
const SizeForWomenComponent = () => {
    return (
      <Form.Group controlId="formSizeForWomen" style={{marginBottom: "20px"}}>
        <Form.Control type="text" placeholder="Enter size for women" required/>
      </Form.Group>
    );
  };
export default SizeForWomenComponent;
