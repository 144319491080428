import React from 'react';
import { Form } from 'react-bootstrap';

const CityComponent = () => {
    return (
      <Form.Group  controlId="formCity" style={{marginBottom: "20px"}}>
        <Form.Control type="text" placeholder="Enter city" required/>
      </Form.Group>
    );
  };
export default CityComponent;