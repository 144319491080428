import { Container, ListGroup, ListGroupItem } from 'react-bootstrap'
import TopNavbar from '../TopNavbar'
export default function Policies(){
    return (
        <>
        <TopNavbar/>
        <h2 style={{marginTop: "20px", marginBottom: "20px"}} className='d-flex justify-content-center'>Terms and Conditions</h2>
        <Container>
        <ListGroup>
            <ListGroup.Item>
                <ListGroup as="ol" numbered>
                    <ListGroup.Item as="li">
                    BY ACCESSING THE WEBSITE AT WEBSITE, YOU ARE AGREEING TO BE BOUND BY THESE TERMS OF SERVICE, ALL APPLICABLE LAWS AND REGULATIONS, AND AGREE THAT YOU ARE RESPONSIBLE FOR COMPLIANCE WITH ANY APPLICABLE LOCAL LAWS. IF YOU DO NOT AGREE WITH ANY OF THESE TERMS, YOU ARE PROHIBITED FROM USING OR ACCESSING THIS SITE.
                    </ListGroup.Item>
                    <ListGroup.Item as="li">THE MATERIALS ON WEBSITE  (WEBSITE) ARE PROVIDED ON AN 'AS IS' BASIS. WEBSITE MAKES NO WARRANTIES, EXPRESSED OR IMPLIED, AND HEREBY DISCLAIMS AND NEGATES ALL OTHER WARRANTIES INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT OF INTELLECTUAL PROPERTY OR OTHER VIOLATION OF RIGHTS. FURTHER, WEBSITE DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS CONCERNING THE ACCURACY, LIKELY RESULTS, OR RELIABILITY OF THE USE OF THE MATERIALS ON ITS WEBSITE OR OTHERWISE RELATING TO SUCH MATERIALS OR ON ANY SITES LINKED TO THIS SITE.</ListGroup.Item>
                    <ListGroup.Item as="li">THE MATERIALS APPEARING ON WEBSITE WEBSITE (WEBSITE) COULD INCLUDE TECHNICAL, TYPOGRAPHICAL, OR PHOTOGRAPHIC ERRORS (WE DO OUR BEST). WEBSITE DOES NOT WARRANT THAT ANY OF THE MATERIALS ON ITS WEBSITE ARE ACCURATE, COMPLETE OR CURRENT. RECEIPTKINGS MAY MAKE CHANGES TO THE MATERIALS CONTAINED ON ITS WEBSITE AT ANY TIME WITHOUT NOTICE. HOWEVER WEBSITE DOES NOT MAKE ANY COMMITMENT TO UPDATE THE MATERIALS.</ListGroup.Item>
                    <ListGroup.Item as="li">WEBSITE HAS NOT REVIEWED ALL OF THE SITES LINKED TO ITS WEBSITE AND IS NOT RESPONSIBLE FOR THE CONTENTS OF ANY SUCH LINKED SITE. THE INCLUSION OF ANY LINK DOES NOT IMPLY ENDORSEMENT BY REPRECEIPT OF THE SITE. USE OF ANY SUCH LINKED WEBSITE IS AT THE USER'S OWN RISK.</ListGroup.Item>
                    <ListGroup.Item as="li">WEBSITE MAY REVISE THESE TERMS OF SERVICE FOR ITS WEBSITE AT ANY TIME WITHOUT NOTICE. BY USING THIS WEBSITE YOU ARE AGREEING TO BE BOUND BY THE THEN CURRENT VERSION OF THESE TERMS OF SERVICE.</ListGroup.Item>
                    <ListGroup.Item as="li">BY PURCHASING, YOU CONFIRM THAT YOU HAVE UNDERSTOOD HOW THE SITE WORKS, ITS PRODUCT DELIVERY METHODS, ITS REFUND POLICY AND ITS PAYMENT METHODS.</ListGroup.Item>
                    <ListGroup.Item as="li">WEBSITE DOES NOT TAKE ANY RESPONSIBILITY FOR THE USE OF ITS PRODUCTS, ALL ITEMS LISTED ON THIS SITE ARE LIMITED TO PERSONAL USE ONLY AND NOT COMMERCIAL OR SOCIAL.</ListGroup.Item>
                </ListGroup>
            </ListGroup.Item>
            <ListGroup.Item>
                <ListGroup>
                    <ListGroup.Item className='fw-bold'>
                        IMPORTANT DISCLAIMER :
                    </ListGroup.Item>
                    <ListGroup.Item>
                    THE RECEIPTS ON WEBSITE ARE MEANT TO BE USED AS NOVELTY ITEMS OR THEATRICAL/STAGE OR PROP USE AND NOT FOR ANY ILLEGAL PURPOSE.
                    </ListGroup.Item>
                    <ListGroup.Item>
                    IF THERE IS ANY MISUSE OF ANY PRODUCT PURCHASED FROM US, WE AT WEBSITE OR OUR EMPLOYEES WILL NOT BE RESPONSIBLE AND WILL NOT BE HELD LIABLE FOR ANY LEGAL PROCEDURES BROUGHT AGAINST ANY PERSONS WHO PARTICIPATE IN ILLEGAL ACTIVITIES USING A PRODUCT CREATED AND SOLD BY US.
                    </ListGroup.Item>
                    <ListGroup.Item>
                    THE RESPONSIBILITY OF USING ANY SUCH ITEM PURCHASED FROM US LIES SOLELY WITH THE CUSTOMER. THE PROVIDER WILL NOT BE RESPONSIBLE FOR ANY SUCH ACTS COMMITTED BY THE CUSTOMER AND CAN NOT BE HELD LIABLE.
                    </ListGroup.Item>
                    <ListGroup.Item>
                    THE CUSTOMER WILL ACCEPT FULL RESPONSIBILITY FOR THEIR ACTIONS WHICH INVOLVES THE USE OF OUR PRODUCTS.
                    </ListGroup.Item>
                    <ListGroup.Item>
                    YOU (THE CUSTOMER) ACCEPTS THAT YOU ARE NOT IN THE EMPLOYMENT OF, OR HAVE NOT BEEN PREVIOUSLY EMPLOYED BY, OR IS NOT ASSOCIATED WITH ANY FORM OF LAW ENFORCEMENT AGENCY (POLICE, FBI, COURTS, LAW OFFICES) OR ANY MEDIA INSTITUTION.
                    </ListGroup.Item>
                    <ListGroup.Item>
                    YOU (THE CUSTOMER) ACCEPT FULL RESPONSIBILITY FOR ANY LEGAL ISSUES THAT MIGHT OCCUR WITH REGARD TO THE LAWS AND STATUTES OF YOUR COUNTRY, DUE TO IMPORTATION AND USE OF ITEM PRODUCED BY OR BOUGHT FROM OUR WEBSITE.
                    </ListGroup.Item>
                    <ListGroup.Item>
                    ANY ITEM SOLD OR ISSUED BY US ARE NOT AUTHENTIC STORE RECEIPTS, THEY ARE JUST DECORATION PAPER PRINTABLE. THEY ARE ONLY TO BE USED AS NOVELTY ITEMS FOR GAGS GIFTS, THEATRICAL PRODUCTIONS, VIDEO PRODUCTIONS OR SIMPLY VIEWING ETC. THE USE OF THESE ITEMS FOR ANY OTHER PURPOSE IS STRONGLY CONDEMNED BY US.
                    </ListGroup.Item>
                    <ListGroup.Item>
                    WE EMPHASIZE CLEARLY THAT WE ARE NOT AFFILIATED TO WITH ANY RETAILER. WE ARE AN INDEPENDENT THIRD PARTY ORGANIZATION.
                    </ListGroup.Item>
                    <ListGroup.Item>
                    BY PURCHASING YOU UNDERSTAND AND ACCEPT THAT YOU WILL HAVE TO PRINT YOUR RECEIPTS YOURSELF AND FOR EMAIL, WE ONLY PROVIDE YOU WITH A .PNG OR .PDF FILE THAT YOU CAN PRINT AND SEND BY EMAIL.
                    </ListGroup.Item>
                    <ListGroup.Item>
                    OUR SERVICE IS NOT TO BE UTILIZED TO CREATE FAKE OR FRAUDULENT INVOICES. THE USER ASSUMES ALL LIABILITY OF USAGE OF ANY INVOICE CREATED ON WEBSITE.
                    </ListGroup.Item>
                </ListGroup>
            </ListGroup.Item>
        </ListGroup>
        </Container>
        </>
    )
}