import React from 'react';
import { Form } from 'react-bootstrap';

const QuantityComponent = () => {
    return (
      <Form.Group controlId="formQuantity" style={{marginBottom: "20px"}}>
        <Form.Control type="text" placeholder="Enter quantity" required/>
      </Form.Group>
    );
  };
  export default QuantityComponent;