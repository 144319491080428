import React, { useState, useRef } from "react";
import {Link, useNavigate} from 'react-router-dom'
import { Container, Row, Col, Card, Alert, Form, FloatingLabel, Button } from 'react-bootstrap';
import { useAuth } from "../contexts/AuthContext";

export default function AdminLogin() {
    const emailRef = useRef('')
    const passwordRef = useRef(0)
    const {login} = useAuth('')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()


    async function handleSubmit(e) {
        e.preventDefault();
        try {
          setError("");
          setLoading(true);
          await login(emailRef.current.value, passwordRef.current.value);
          navigate("/admin/dashboard");
        } catch (error) {
          console.error("Error in handleSubmit:", error); 
          setError("Failed to sign in");
        }
        setLoading(false);
      }

  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col md={6}>
            <Card className="mt-5">
              <Card.Body>
                <h2 className="text-center mb-4">Log In</h2>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={handleSubmit}>
                  <FloatingLabel controlId="floatingInput" label="Email address" className="mb-3">
                    <Form.Control type="email" placeholder="name@example.com" ref={emailRef} required />
                  </FloatingLabel>
                  <FloatingLabel controlId="floatingPassword" label="Password">
                    <Form.Control type="password" placeholder="Password" ref={passwordRef} required />
                  </FloatingLabel>
                  <div style={{ height: "10px", width: "100%" }}></div>
                  <Button disabled={loading} className="w-100" type="submit">
                    Log In
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}