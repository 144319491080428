import React from 'react';
import { Form } from 'react-bootstrap';

const CountryComponent = () => {
    return (
      <Form.Group  controlId="formCountry" style={{marginBottom: "20px"}}>
        <Form.Control type="text" placeholder="Enter country" required/>
      </Form.Group>
    );
  };
  export default CountryComponent;
