import React from 'react';
import { Container } from 'react-bootstrap';


export default function ItemHeader({ itemData }) {
    let price;
    if (itemData.type === 'email' || itemData.type === 'paper') { 
        price = '9.5';
    } else {
        price = '20';
    }   
  return (
    <Container>
        <h3            
              style={{
                marginBottom: "20px",
                marginTop: "20px",
                textTransform: "uppercase",
                textAlign: "center"
              }}
            > {itemData.name} - ${price}</h3>
    </Container>
  );
}
