import TopNavbar from '../TopNavbar'
import { secondaryDB } from "../firebase";
import {collection, onSnapshot, doc} from 'firebase/firestore'
import { useEffect, useState } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

export default function Landingpage(){

  const [emailItems, setEmailItems] =  useState([]);
  const [paperItems, setPaperItems] =  useState([]);
  const [appItems, setAppItems] =  useState([]);  
  const itemsCollectionRef = collection(secondaryDB, '/items');
  const { affiliateID } = useParams();

  useEffect(() => {
    const unsubscribe = onSnapshot(itemsCollectionRef, (data) => {
      const items = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setEmailItems(items.filter((item) => item.type === "email"));
      setPaperItems(items.filter((item) => item.type === "paper"));
      setAppItems(items.filter((item) => item.type === "app"));
    });
  
    return () => {
      unsubscribe();
    };
  }, []);

  function Items({ items, title, affiliateID }) {
    return (
      items.length !== 0 && (
        <>
          <div className='fs-1 mb-4 mt-3'>{title}</div>
          <Row>
            {items.map((item) => (
              <Col key={item.id} xs={3} sm={3} md={3} lg={2} className='mb-3'>
                <Link to={`/item/${item.id}${affiliateID ? `/${affiliateID}` : ''}`}>
                  <Image src={item.thumbnailUrl} fluid />
                </Link>
              </Col>
            ))}
          </Row>
        </>
      )
    );
  }
      return (
        <>
        <TopNavbar/>
        <Container>
          <Items items={emailItems} title="Email Invoice" affiliateID={affiliateID} />
          <Items items={paperItems} title="A4 paper Invoice" affiliateID={affiliateID} />
          <Items items={appItems} title="App Invoice" affiliateID={affiliateID} />
        </Container>
        </>
      );
}