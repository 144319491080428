import React, { useState } from 'react';
import { Carousel, Image, Modal } from 'react-bootstrap';

export default function ProductCarousel({ itemData }) {
  const [index, setIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setShowModal(true);
  };

  if (itemData.length === 1) {
    return (
      <>
        <Image
          className="img-fluid"
          src={itemData[0]}
          alt="Product Image"
          onClick={() => handleImageClick(itemData[0])}
          style={{ cursor: 'pointer', transform: "scale(1.25,1.25)", marginTop: "20px" }}
        />
        <Modal show={showModal} onHide={() => setShowModal(false)} centered>
          <Modal.Body>
            <img
              className="d-block w-100 img-fluid"
              src={selectedImage}
              alt="Full Size Image"
            />
            <a href={selectedImage} target="_blank" rel="noreferrer" style={{color: "black"}}>
              View Image in New Tab
            </a>
          </Modal.Body>
        </Modal>
      </>
    );
  } else {
    return (
      <>
        <Carousel activeIndex={index} onSelect={handleSelect}>
          {itemData.map((imageUrl, i) => (
            <Carousel.Item key={i}>
              <img
                className="d-block w-100 img-fluid"
                src={imageUrl}
                alt={`Slide ${i}`}
                onClick={() => handleImageClick(imageUrl)}
                style={{ cursor: 'pointer' }}
              />
            </Carousel.Item>
          ))}
        </Carousel>
        <Modal centered show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Body>
            <img
              className="d-block w-100 img-fluid"
              src={selectedImage}
              alt="Full Size Image"
            />
            <a href={selectedImage} target="_blank" rel="noreferrer" style={{color: "black"}}>
              View Image in New Tab
            </a>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
