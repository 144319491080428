import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import ProductCarousel from './ProductCarousel';
import styles from './ProductDescription.module.css';

export default function ProductDescription({ itemData }) {
  function insertNewline(text) {
    return text.replace(/([.?!])\s*(?=[A-Z])/g, "$1\n\n");
  }

  return (
    <Container style={{marginBottom:"10px", marginTop: "20px"}}>
    <Row>
      <Col xl={5} md={5} xs={5} style={{marginBottom: "40px", overflow: "hidden"}}>
      <ProductCarousel itemData={itemData.imageUrls} />
      </Col>
      <Col xl={7} md={7} xs={7}>
      <Container>
        <p className={styles.description} style={{whiteSpace: 'pre-wrap'}}>
          {insertNewline(itemData.description)}
        </p>
        
      </Container>
      </Col>
    </Row>
    </Container>
  );
}
