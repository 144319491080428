import React from 'react';
import { Form } from 'react-bootstrap';
const EmailComponent = () => {
    return (
      <Form.Group controlId="formEmail" style={{marginBottom: "20px"}}>
        <Form.Control type="email" placeholder="Enter email" required/>
      </Form.Group>
    );
  };
export default EmailComponent;
