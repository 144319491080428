import { Col, Container, Row } from 'react-bootstrap'
import TopNavbar from '../TopNavbar'
import {FaTelegramPlane, FaInstagram} from "react-icons/fa"
import { Link } from 'react-router-dom'
export default function Contact(){
    return (
        <>
        <TopNavbar/>
        <Container style={{marginTop:"20px"}} className='text-center'>
        <h1>Got a question?</h1>
        <h2>Don't hesitate to contact us!</h2>
        <hr />
        <b className='fs-3'>via</b>
        <hr />
        <Container style={{ marginBottom:"30px"}}className='d-flex justify-content-center align-items-center'>
        <a href='https://t.me/FKreceipt' target='_blank' rel='noopener noreferrer'><FaTelegramPlane size={50}/></a>
            <div className='mx-3 fs-3'>or</div>
        <a href='https://instagram.com/fkreceipts?igshid=MzRlODBiNWFlZA==' target='_blank' rel='noopener noreferrer'><FaInstagram size={50}/></a>
        </Container>
        <p className='fs-4'>We will reach out to you as soon as possible</p>
        </Container>
        </>
    )
}