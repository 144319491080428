import React from 'react';
import { Form } from 'react-bootstrap';

const ProductNameComponent = () => {
    return (
        <Form.Group controlId='formProductName' style={{marginBottom: "20px"}}>
        <Form.Control as="select" required>
          <option>Apple Watch Ultra(Yellow)</option>
          <option>Apple Watch Ultra(Midnight)</option>
          <option disabled>==========================</option>
          <option>Air Pods Max(Space Grey)</option>
          <option>Air Pods Max(Silver)</option>
          <option>Air Pods Max(Sky Blue)</option>
          <option disabled>==========================</option>
          <option>AirPods (2nd generation)</option>
          <option disabled>==========================</option>
          <option>AirPods Pro (1st generation)</option>
          <option>AirPods Pro (2nd generation)</option>
          <option disabled>==========================</option>
          <option>AirPods (3rd generation) (with Lightning Charging Case)</option>
          <option>AirPods (3rd generation) (with MagSafe Charging Case)</option>
        </Form.Control>
      </Form.Group>
    );
  };
  export default ProductNameComponent;
