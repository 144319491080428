import React, { useState } from "react";
import axios from "axios";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { ToastContainer } from 'react-toastify';
const PaymentButton = ({ price, sendOrder }) => {
  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    try {
      // Call the firebase function to create a payment request
      const response = await axios.post(
        "https://us-central1-fkreceipts-d87cc.cloudfunctions.net/api/createPayment",
        {
          price: price,
        }
      );
      // Get the payment URL from the response
      await sendOrder(response.data.id)
      const paymentUrl = response.data.payment_url;
      // Redirect the user to the payment URL
      console.log(paymentUrl)
      window.open(paymentUrl, '_blank');
      toast.success("Thank you for ordering! We will check the payment and get back to you!")
        } catch (error) {
      // Handle any errors
      console.error(error);
      alert("Something went wrong. Please try again later.");
    } finally {
      setLoading(false);
      setFinished(true)
    }
  };

  return (
    <>
    <ToastContainer/>
    <Button onClick={handleClick} disabled={loading || finished}
    type="submit"
    size="lg"
    style={{
      backgroundColor: "#000000",
      borderColor: "#000000",
      width: "300px",
    }}
    >
      {loading ? "Loading..." : `Pay ${price} USD with Crypto`}
    </Button>
    </>
  );
};

export default PaymentButton;