import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { getFirestore } from "@firebase/firestore";
import { getStorage, ref } from "firebase/storage";

export const app = firebase.initializeApp({
    apiKey: process.env.REACT_APP_API,
    authDomain: process.env.REACT_APP_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT,
    storageBucket: process.env.REACT_APP_STORAGE,
    messagingSenderId: process.env.REACT_APP_MESSAGING,
    appId: process.env.REACT_APP_APP
})

export const auth = app.auth()
export const db = getFirestore(app);
export const storage = getStorage(app);

export const secondaryApp = firebase.initializeApp({
    apiKey: "AIzaSyClIwkf2BZRa4VtuDMSQlpgD0powJMjpTw",
    authDomain: "fkreceipts-d87cc.firebaseapp.com",
    projectId: "fkreceipts-d87cc",
    storageBucket: "fkreceipts-d87cc.appspot.com",
    messagingSenderId: "999675221453",
    appId: "1:999675221453:web:604622aa6c683dc6042084"
  }, "secondary");
export const secondaryDB = getFirestore(secondaryApp)
  