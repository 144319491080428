import React from 'react';
import { Form } from 'react-bootstrap';

const AddressComponent = () => {
    return (
      <Form.Group  controlId="formAddress" style={{marginBottom: "20px"}}>
        <Form.Control type="text" placeholder="Enter address" required/>
      </Form.Group>
    );
  };

export default AddressComponent;
