import React, { useState, useEffect } from 'react';
import { Form, Button, Container } from 'react-bootstrap';
import { collection, addDoc, getDocs } from "firebase/firestore";
import 'firebase/firestore';
import {ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { secondaryDB, storage } from '../firebase'; // Import the Firestore secondaryDB reference
import { ToastContainer, toast } from 'react-toastify';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import {RxCross1} from 'react-icons/rx'
import { StrictModeDroppable } from './components/StrictModeDroppable';
import TopAdminNavbar from '../TopAdminNavbar';

const AdminDashboard = () => {
  const [name, setName] = useState('');
  const [imageURL, setImageURL] = useState('');
  const [type, setType] = useState('email')
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(null);
  const [images, setImages] = useState([]);
  const [selectedCheckboxNames, setSelectedCheckboxNames] = useState([]);
  const [checkboxes, setCheckboxes] = useState({});
  const [loading, setLoading] = useState(false)
  const [questionsSaved, setQuestionsSaved] = useState(false);
  const defaultCheckboxOptions = [
    'region',
    'name',
    'surname',
    'quantity',
    'material',
    'date(mm/dd/yyyy)',
    'zip code',
    'email',
    'price',
    'address',
    'colour',
    'EU size',
    'url',
    'size for men',
    'size for women',
    'gender(male/female)',
    'country',
    'cur',
    'total',
    'US size',
    'city',
    'ship',
    'size',
    'product name',
    'instagram',
    'product name text',
    'image URL'
  ];
  const [existingKeys, setExistingKeys] = useState([]);

  useEffect(() => {
    // Fetch existing questions from the database
    const fetchExistingKeys = async () => {
      const questionCollectionRef = collection(secondaryDB, 'questions');
      const querySnapshot = await getDocs(questionCollectionRef);
      const keys = [];

      querySnapshot.forEach((doc) => {
        keys.push(doc.data().key);
      });

      setExistingKeys(keys);
    };

    fetchExistingKeys();
  }, []);

  // Append the existing keys to the checkboxOptions array
  const checkboxOptions = [...defaultCheckboxOptions, ...existingKeys];

  const handleRemoveImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };
  
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'images') {
      setImages((prevImages) => [...prevImages, ...event.target.files]);
    } else if (name === 'image') {
      setImage(event.target.files[0]);
    } else if (name.startsWith('checkbox')) {
      setCheckboxes({
        ...checkboxes,
        [name]: event.target.checked,
      });
    } else if (name === 'name') {
      setName(value);
    } else if (name === 'type') {
      setType(value);
    } else if (name === 'description') {
      setDescription(value);
    }
  };
  
  const AdminForm = ({ checkboxOptions }) => {
    const [checkboxes, setCheckboxes] = useState({});

  
    const handleChange = (event) => {
      const { name, checked } = event.target;
      setCheckboxes((prevState) => ({ ...prevState, [name]: checked }));
    };
  
    const handleSave = () => {
      // Get selected checkbox names
      const selectedNames = checkboxOptions.filter((option, index) => {
        const checkboxName = `checkbox-${index}`;
        return checkboxes[checkboxName];
      });
      setQuestionsSaved(true);
      setSelectedCheckboxNames(selectedNames);
      
    };
  
    const handleDragEnd = (result) => {
      if (!result.destination) return;
      const items = Array.from(selectedCheckboxNames);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      setSelectedCheckboxNames(items);
    };
  
    return (
      <>
        <Form.Group style={{ marginBottom: '20px' }}>
          <Form.Label>Questions for form</Form.Label>
          <br />
          {checkboxOptions.map((option, index) => (
            <Form.Check
              type="checkbox"
              key={option}
              label={option}
              name={`checkbox-${index}`}
              checked={checkboxes[`checkbox-${index}`] || false}
              onChange={handleChange}
            />
          ))}
        </Form.Group>
        <Button onClick={handleSave}>Save Selected Questions</Button>
        <DragDropContext onDragEnd={handleDragEnd}>
          <StrictModeDroppable droppableId="questions">
            {(provided) => (
              <ul {...provided.droppableProps} ref={provided.innerRef}>
                {selectedCheckboxNames.map((name, index) => (
                  <Draggable key={name} draggableId={name} index={index}>
                    {(provided) => (
                      <li
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                      >
                        {name}
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </StrictModeDroppable>
        </DragDropContext>
        {questionsSaved &&<Button variant="primary" type="submit" disabled={loading} style={{marginBottom: "20px"}}>Submit</Button>}
      </>
    );
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      // Upload the thumbnail image to Firebase Storage (if image is selected)
      let thumbnailUrl = '';
      if (image) {
        const storageRef = ref(storage, name);
        await uploadBytes(storageRef, image);
        thumbnailUrl = await getDownloadURL(storageRef);
      }
  
      // Upload the product images to Firebase Storage
      const imageUrls = await Promise.all(
        images.map(async (image) => {
          const storageRef = ref(storage, image.name);
          await uploadBytes(storageRef, image);
          const url = await getDownloadURL(storageRef);
          return url;
        })
      );
  
  
      // Add document to Firestore
      await addDoc(collection(secondaryDB, '/items'), {
        name: name,
        type: type,
        description: description,
        thumbnailUrl: thumbnailUrl,
        imageUrls: imageUrls,
        checkboxNames: selectedCheckboxNames,
      });
  
      // Clear form fields after successful submission
      setName('');
      setType('email');
      setDescription('');
      setImage(null);
      setImages([]);
      setCheckboxes({});
      setImageURL('');
      setLoading(false);
      toast.success("Product added to the store!");
      setSelectedCheckboxNames([])
      setQuestionsSaved(false)
    } catch (error) {
      console.error('Error adding document: ', error);
      toast.error("Error adding product");
    }
  };
  
  

  return (
    <>
    <TopAdminNavbar/>
    <Container>
      <ToastContainer/>
      
        <br/>
      <h1>Admin Dashboard</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="name" style={{marginBottom: "20px"}}>
          <Form.Label>Name</Form.Label>
          <Form.Control type="text" name="name" value={name} onChange={handleChange} required/>
        </Form.Group>

        <Form.Group controlId="type" style={{marginBottom: "20px"}}>
          <Form.Label>Type</Form.Label>
              <Form.Check
                type="radio"
                label="Email"
                name="type"
                value="email"
                checked={type === 'email'}
                onChange={handleTypeChange}
              />

              <Form.Check
                type="radio"
                label="Paper"
                name="type"
                value="paper"
                checked={type === 'paper'}
                onChange={handleTypeChange}
              />

              <Form.Check
                type="radio"
                label="App"
                name="type"
                value="app"
                checked={type === 'app'}
                onChange={handleTypeChange}
              />

        </Form.Group>


        <Form.Group controlId="description" style={{marginBottom: "20px"}}>
          <Form.Label>Description</Form.Label>
          <Form.Control
            required
            as="textarea"
            rows={3}
            name="description"
            value={description}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="image" style={{marginBottom: "20px"}}>
          <Form.Label>Image for Landing Page</Form.Label>
          <Form.Control type="file" name="image" onChange={handleChange} required/>
        </Form.Group>

        <Form.Group controlId="images" style={{ marginBottom: "20px" }}>
          <Form.Label>Images</Form.Label>
          <Form.Control required type="file" name="images" onChange={handleChange} multiple />
        </Form.Group>
        {images.length > 0 && (
          <div>
            <h5>Selected Images:</h5>
            <div>
              {images.map((image, index) => (
                <div key={index} style={{ display: "inline-block", margin: "10px", position: "relative" }}>
                  <img
                    src={URL.createObjectURL(image)}
                    alt={`Selected image ${index + 1}`}
                    style={{ width: "100px", height: "100px", objectFit: "cover" }}
                  />
                  <span
                    onClick={() => handleRemoveImage(index)}
                    style={{
                      position: "absolute",
                      top: "-10px",
                      right: "-10px",
                      cursor: "pointer",
                      backgroundColor: "white",
                      borderRadius: "50%",
                      padding: "2px",
                      lineHeight: "0"
                    }}
                  >
                    <RxCross1/>
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}



        <AdminForm checkboxOptions={checkboxOptions}/>
      </Form>
    </Container>
    </>
  );

};

export default AdminDashboard;
