import React from 'react';
import { Form } from 'react-bootstrap';

const DateComponent = () => {
    return (
      <Form.Group controlId="formDate" style={{marginBottom: "20px"}}>
        <Form.Control type="date" placeholder="Enter date" required/>
      </Form.Group>
    );
  };
export default DateComponent;