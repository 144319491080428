import React from 'react';
import { Form } from 'react-bootstrap';

const RegionComponent = () => {
  return (
    <Form.Group controlId="formRegion" style={{marginBottom: "20px"}}>
      <Form.Control type="text" placeholder="Enter region" required/>
    </Form.Group>
  );
};

export default RegionComponent;