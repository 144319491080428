import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import logo from "./logo.png"
const  NavigationBar = () => {
  const { affiliateID } = useParams();
  return (
    
    <>
    <div style={{height: "3px", background: "#FFFFFF"}}></div>
    <div style={{ backgroundColor: 'black', padding: '10px', textAlign: "center"}}>
    <a style={{color: "white", fontWeight: "bold", textDecoration: "none"}} href='https://instagram.com/checkerzofficial?igshid=OGQ5ZDc2ODk2ZA==' target='_blank' rel='noopener noreferrer'>JOIN OUR INSTAGRAM!</a>
    </div>
        
    <Navbar bg="light" expand="lg" sticky="top">
      
      <Container>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link as={Link} to={`${affiliateID ? `/${affiliateID}` : '/'}`}>Catalog</Nav.Link>
            <Nav.Link as={Link} to={`/contact${affiliateID ? `/${affiliateID}` : ''}`}>Contact</Nav.Link>
            <Nav.Link as={Link} to={`/policies${affiliateID ? `/${affiliateID}` : ''}`}>Policies</Nav.Link>
            
          </Nav>
        </Navbar.Collapse>
        <Navbar.Brand href={`${affiliateID ? `/${affiliateID}` : '/'}`} className="mx-auto" style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
          <img alt="" src={logo} width="120" className="d-inline-block align-top" />
        </Navbar.Brand>
      </Container>
    </Navbar>
    <div style={{height: "3px", background: "#000000"}}></div>
    </>
  );
};

export default NavigationBar;
