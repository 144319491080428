import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { secondaryDB } from '../../firebase';

const DynamicComponent = ({ question }) => {
  const [formControl, setFormControl] = useState('');
  const [type, setType] = useState('');
  const [text, setText] = useState('');
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      // Fetch data from the database based on the question prop
      const questionCollectionRef = collection(secondaryDB, 'questions');
      const q = query(questionCollectionRef, where('key', '==', question));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.size === 1) {
        const doc = querySnapshot.docs[0];
        const data = doc.data();
        const controlId = data.key
          .split(' ')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join('');
        // Extract data and set state variables
        setFormControl(controlId);
        setType(data.type);
        setText(data.text);

        // If type is 'select', set options
        if (data.type === 'select') {
          setOptions(data.options || []);
        }
      }
    };

    fetchData();
  }, [question]);

  return (
    <Form.Group controlId={formControl} style={{ marginBottom: '20px' }}>
      {type === 'select' ? (
        <Form.Control as="select" required>
          {options.map((option, index) => (
            <option key={index} value={option.text} disabled={option.isDisabled}>
              {option.text}
            </option>
          ))}
        </Form.Control>
      ) : (
        <Form.Control type={type} placeholder={text} required />
      )}
    </Form.Group>
  );
};

export default DynamicComponent;
