import React from 'react';
import { Form } from 'react-bootstrap';

const CurComponent = () => {
    return (
      <Form.Group controlId="formCur" style={{marginBottom: "20px"}}>
        <Form.Control as="select" required>
          <option>EUR</option>
          <option>USD</option>
          <option>AUD</option>
          <option>PLN</option>
          <option>GBP</option>
          <option>CAD</option>
          <option>NOK</option>
          <option>SEK</option>          
        </Form.Control>
      </Form.Group>
    );
  };
export default CurComponent;
