import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { db, secondaryDB } from "../firebase";
import { collection, doc, getDoc, getDocs, where, query, updateDoc } from "firebase/firestore";
import TopNavbar from "../TopNavbar";
import {
  Container,
  Form,
  Button,
  Modal,
  InputGroup,
} from "react-bootstrap";
import RegionComponent from "./components/RegionComponent";
import NameComponent from "./components/NameComponent";
import SurnameComponent from "./components/SurnameComponent";
import QuantityComponent from "./components/QuantityComponent";
import MaterialComponent from "./components/MaterialComponent";
import DateComponent from "./components/DateComponent";
import ZipCodeComponent from "./components/ZipCodeComponent";
import EmailComponent from "./components/EmailComponent";
import PriceComponent from "./components/PriceComponent";
import AddressComponent from "./components/AddressComponent";
import ColourComponent from "./components/ColourComponent";
import EUSizeComponent from "./components/EUSizeComponent";
import UrlComponent from "./components/UrlComponent";
import SizeForMenComponent from "./components/SizeForMenComponent";
import SizeForWomenComponent from "./components/SizeForWomenComponent";
import GenderComponent from "./components/GenderComponent";
import CountryComponent from "./components/CountryComponent";
import CurComponent from "./components/CurComponent";
import TotalComponent from "./components/TotalComponent";
import USSizeComponent from "./components/USSizeComponent";
import CityComponent from "./components/CityComponent";
import ShipComponent from "./components/ShipComponent";
import SizeComponent from "./components/SizeComponent";
import ProductDescription from "./components/ProductDescription";
import ProductNameComponent from "./components/ProductNameComponent";
import InstagramComponent from "./components/InstagramComponent";
import { addDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { ToastContainer } from 'react-toastify';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import ProductNameTextComponent from "./components/ProductNameTextComponent";
import ImageUrlComponent from "./components/ImageURLComponent";
import ItemHeader from "./components/ItemHeader";
import DynamicComponent from "./components/DynamicComponent";
import PaymentButton from "./components/PaymentButton";

const ItemPage = () => {
  const { id } = useParams();
  const [itemData, setItemData] = useState(null);
  const itemsCollectionRef = collection(secondaryDB, "items");
  const orderRef = collection(db, "orders");
  const [loading, setLoading] = useState(false);
  const [orderFormData, setOrderFormData] = useState([]);
  const [show, setShow] = useState(false);
  const [showPayPalButton, setShowPayPalButton] = useState(false);
  const { affiliateID } = useParams();
  const [formData, setFormData] = useState({});
  const [stripeAmount, setStripeAmount] = useState(2000)
  const [promoCode, setPromoCode] = useState('');
  const [discountApplied, setDiscountApplied] = useState(0);
  const [promoCodeDisabled, setPromoCodeDisabled] = useState(false);
  const [paypalAmount, setPaypalAmount] = useState(null);
  const [paypalKey, setPaypalKey] = useState(0);
  useEffect(() => {
    let price = itemData && (itemData.type === 'email' || itemData.type === 'paper') ? 9.5 : 20;
    setPaypalAmount(applyDiscount(price).toFixed(2));
    setPaypalKey(prevKey => prevKey + 1); 
  }, [itemData, discountApplied]);
  useEffect(() => {
    if (itemData) {
      // Determine the base amount based on item type
      let baseAmount = itemData.type === 'email' || itemData.type === 'paper' ? 950 : 2000;
  
      // Apply discount if any
      let discountedAmount = applyDiscount(baseAmount);
  
      // Update the stripeAmount state
      setStripeAmount(discountedAmount);
    }
  }, [itemData, discountApplied]);
  
  useEffect(() => {
    setLoading(true);
    const docRef = doc(itemsCollectionRef, id);
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        setItemData(docSnap.data());
      } else {
        toast.error("Error, no data");
      }
    });

    const fetchData = async () => {
      // Fetch all documents from the 'questions' collection
      const questionCollectionRef = collection(secondaryDB, 'questions');
      const querySnapshot = await getDocs(questionCollectionRef);

      const questionData = {};

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        // Create the controlId based on the key
        const controlId = data.key
          .split(' ')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join('');
        questionData[data.key] = controlId;
      })
      setFormData(questionData)
    
    }
    fetchData()
    setLoading(false);
  }, [id]);
  const incrementPromoCodeUsage = async (promoCodeName) => {
    if (!promoCodeName) return;
  
    const promoCodeRef = collection(secondaryDB, 'promocodes');
    const q = query(promoCodeRef, where('code', '==', promoCodeName));
    const querySnapshot = await getDocs(q);
  
    if (!querySnapshot.empty) {
      const promoDoc = querySnapshot.docs[0];
      const currentTimesUsed = promoDoc.data().timesUsed || 0;
      await updateDoc(promoDoc.ref, { timesUsed: currentTimesUsed + 1 });
    }
  };
  const handlePromoCodeSubmit = async () => {
      const promoCodeRef = collection(secondaryDB, 'promocodes');
      const q = query(promoCodeRef, where('code', '==', promoCode));
      const querySnapshot = await getDocs(q);
  
      if (!querySnapshot.empty) {
        const promo = querySnapshot.docs[0].data();
        setDiscountApplied(promo.discount);
        setPromoCodeDisabled(true); // Disable promo code input after successful validation
        toast.success("Promo code successfully applied!");
      } else {
        toast.error("Invalid promo code");
        setPromoCode(null)
      }
    };
  
    const applyDiscount = (amount) => {
      return amount - (amount * discountApplied / 100);
    };
    const handlePaymentSelection = () => {
      setPromoCodeDisabled(true); // Disable promo code input when a payment option is selected
    };
  const sendOrderViaCrypto = async (paymentId) => {
    let orderData = { ...orderFormData, crypto: true, cryptoPaymentId: paymentId}
    try {
    if (affiliateID) {
      orderData.affiliateID = affiliateID.split('=')[1];
    }
      // Save the data to Firestore
      await addDoc(orderRef, orderData);
      if (promoCode) {
        incrementPromoCodeUsage(promoCode);
      }
    } catch (error) {
      throw new Error('Failed to send order. Try again later!');
    }
  }
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const form = event.target;
    const defaultCheckboxOptions = {
      region: "formRegion",
      name: "formName",
      surname: "formSurname",
      quantity: "formQuantity",
      material: "formMaterial",
      "date(mm/dd/yyyy)": "formDate",
      "zip code": "formZipCode",
      email: "formEmail",
      price: "formPrice",
      address: "formAddress",
      colour: "formColour",
      "EU size": "formEUSize",
      url: "formUrl",
      "size for men": "formSizeForMen",
      "size for women": "formSizeForWomen",
      "gender(male/female)": "formGender",
      country: "formCountry",
      cur: "formCur",
      total: "formTotal",
      "US size": "formUSSize",
      city: "formCity",
      ship: "formShip",
      size: "formSize",
      "product name": "formProductName",
      instagram: "formInstagram",
      "product name text": "formProductNameText",
      "image URL": "formImageUrl"
    };
    console.log(formData)
    const checkboxOptions={...formData, ...defaultCheckboxOptions}
    // create an orderData object with all the values
    const orderData = {};
    itemData.checkboxNames.forEach((key) => {
      
      if (checkboxOptions[key] !== undefined) {
        const value = form[checkboxOptions[key]].value;
        if (value !== undefined) {
          orderData[key] = value;
        }
      }
    });
    //add additional fields
    orderData.orderName = itemData.name;
    orderData.orderedAt = new Date();
    console.log(orderData)
    setOrderFormData(orderData);
    setShowPayPalButton(true);
  };

  const OrderModal = () => {
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Thank you for the purchase!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Your order was sent successfully! We will get back to you as soon as
            possible.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  return (
    <>
      <TopNavbar />
      <ToastContainer />
      <Container>
        {itemData && (
          <>
            <ItemHeader itemData={itemData}/>
            <ProductDescription itemData={itemData} />
            <Container className="d-flex justify-content-center align-items-center flex-column">
            {(showPayPalButton) ?
                        (
                      <div style={{margin: "30px"}}>
                        <Form                          
                        style={{
                            width: "300px",
                          }}>
                        <Form.Group>
                          <InputGroup>
                          <Form.Control 
                            type="text" 
                            placeholder="Enter promo code" 
                            value={promoCode} 
                            onChange={(e) => setPromoCode(e.target.value)} 
                            disabled={promoCodeDisabled}
                          />
                          <Button variant="primary"                       
                          style={{
                            backgroundColor: "#000000",
                            borderColor: "#000000",
                            width: "125px",
                          }}
                      onClick={handlePromoCodeSubmit} disabled={promoCodeDisabled}>Apply Promo Code</Button>
                          </InputGroup>
                        </Form.Group>
                      </Form>
                      </div>
                      ) : null}
            <PayPalScriptProvider options={{ "client-id": "AVOuycGm28EOw_iej_mPMkkPxUHyOuLYp3ouhjFIiYqSBdGhBEHugtvd_Y2_QN8XTDpQ6EjWbnAGHyC8" }}>
                {!showPayPalButton ? <Form
                  style={{ marginBottom: "20px" }}
                  onSubmit={handleFormSubmit}
                >
                  <h2 style={{marginBottom: "20px"}}>Fill out the form to order</h2>
                  <div style={{ marginBottom: "20px", maxWidth: "400px" }}>
                    {itemData.checkboxNames.map((question) =>
                      question === "region" ? (
                        <RegionComponent key={question} />
                      ) : question === "name" ? (
                        <NameComponent key={question} />
                      ) : question === "surname" ? (
                        <SurnameComponent key={question} />
                      ) : question === "quantity" ? (
                        <QuantityComponent key={question} />
                      ) : question === "material" ? (
                        <MaterialComponent key={question} />
                      ) : question === "date(mm/dd/yyyy)" ? (
                        <DateComponent key={question} />
                      ) : question === "zip code" ? (
                        <ZipCodeComponent key={question} />
                      ) : question === "email" ? (
                        <EmailComponent key={question} />
                      ) : question === "price" ? (
                        <PriceComponent key={question} />
                      ) : question === "address" ? (
                        <AddressComponent key={question} />
                      ) : question === "colour" ? (
                        <ColourComponent key={question} />
                      ) : question === "EU size" ? (
                        <EUSizeComponent key={question} />
                      ) : question === "url" ? (
                        <UrlComponent key={question} />
                      ) : question === "size for men" ? (
                        <SizeForMenComponent key={question} />
                      ) : question === "size for women" ? (
                        <SizeForWomenComponent key={question} />
                      ) : question === "gender(male/female)" ? (
                        <GenderComponent key={question} />
                      ) : question === "country" ? (
                        <CountryComponent key={question} />
                      ) : question === "cur" ? (
                        <CurComponent key={question} />
                      ) : question === "total" ? (
                        <TotalComponent key={question} />
                      ) : question === "US size" ? (
                        <USSizeComponent key={question} />
                      ) : question === "city" ? (
                        <CityComponent key={question} />
                      ) : question === "ship" ? (
                        <ShipComponent key={question} />
                      ) : question === "size" ? (
                        <SizeComponent key={question} />
                      ) : question === "product name" ? (
                        <ProductNameComponent key={question} />
                      ) : question === "instagram" ? (
                        <InstagramComponent key={question} />
                      ) : question === "product name text" ? (
                        <ProductNameTextComponent key={question} />
                      ) : question === "image URL" ? (
                        <ImageUrlComponent key={question} />
                      ) : question ? (
                        <DynamicComponent question={question} key={question}/>
                      ) :
                      null
                    )}
                  </div>
                  <div className="d-flex justify-content-center align-items-center">
                    <Button
                      disabled={loading}
                      type="submit"
                      size="lg"
                      style={{
                        backgroundColor: "#000000",
                        borderColor: "#000000",
                        width: "300px",
                      }}
                    >
                      Order
                    </Button>
                  </div>
                </Form> : 
                <div><PaymentButton price={stripeAmount/100} sendOrder={sendOrderViaCrypto}/> <div style={{height:"10px"}}></div>
                </div>}
                {showPayPalButton && (
                  <PayPalButtons
                  key={paypalKey}
                    createOrder={(data, actions) => {
                      return actions.order.create({
                        purchase_units: [
                          {
                            amount: {
                              value: paypalAmount,
                            },
                          },
                        ],
                      });
                    }}
                    onApprove={async (data, actions) => {
                      setShow(true);
                      try {
                        // Capture the payment
                        const order = await actions.order.capture();
                        // Add the orderID to the orderFormData object
                        const updatedOrderFormData = { ...orderFormData, orderID: data.orderID};
                        if (affiliateID) {
                          updatedOrderFormData.affiliateID = affiliateID.split('=')[1];
                        }
                        // Save the data to Firestore
                        await addDoc(orderRef, updatedOrderFormData);
                        if (promoCode) {
                          incrementPromoCodeUsage(promoCode);
                        }
                      } catch (error) {
                        throw new Error('Failed to send order. Try again later!');
                      }
                    }}
                                      
                  />
                )}
                </PayPalScriptProvider>
            </Container>
          </>
        )}
      </Container>
      <OrderModal />
      <div style={{height:"100px"}}></div>
    </>
  );
};

export default ItemPage;
