import React from 'react';
import { Form } from 'react-bootstrap';
const ShipComponent = () => {
    return (
      <Form.Group controlId="formShip" style={{marginBottom: "20px"}}>
        <Form.Control as="select" required>
          <option>No Rush(0$)</option>
          <option>Standard(8$)</option>
          <option>Express(30$)</option>
        </Form.Control>
      </Form.Group>
    );
  };
export default ShipComponent;