import React from 'react';
import { Form } from 'react-bootstrap';

const ZipCodeComponent = () => {
    return (
      <Form.Group controlId="formZipCode" style={{marginBottom: "20px"}}>
        <Form.Control type="text" placeholder="Enter zip code" required/>
      </Form.Group>
    );
  };

export default ZipCodeComponent;