import React from 'react';
import { Form } from 'react-bootstrap';
const GenderComponent = () => {
    return (
      <Form.Group controlId="formGender" style={{marginBottom: "20px"}}>
        <Form.Control as="select" required>
          <option>Male</option>
          <option>Female</option>
        </Form.Control>
      </Form.Group>
    );
  };
export default GenderComponent;
