import React from 'react';
import { Form } from 'react-bootstrap';

const SurnameComponent = () => {
    return (
      <Form.Group controlId="formSurname" style={{marginBottom: "20px"}}>
        <Form.Control type="text" placeholder="Enter surname" required/>
      </Form.Group>
    );
  };

  export default SurnameComponent;
